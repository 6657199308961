import { PageProps } from 'gatsby';
import React from 'react';
import DynamicTemplate from '../components/templates/DynamicTemplate/DynamicTemplate.component';
import Meta from '../components/utils/Meta/Meta.component';
import FiltersContext from '../contexts/FiltersContext';
import LayoutContext from '../contexts/LayoutContext';
import VenuesContext from '../contexts/VenuesContext';
import { HeadProps } from '../interfaces/Head.interface';
import { StoryblokEntry, StoryblokEntryWithVenues } from '../interfaces/Storyblok/StoryblokEntry.interface';
import StoryblokEntryTemplateContext from '../interfaces/Storyblok/StoryblokEntryTemplateContext.interface';
import { getParsedJSON } from '../utils/getParsedJSON/getParsedJSON.util';

const getContext = (context: StoryblokEntryWithVenues): StoryblokEntryTemplateContext => {
  const storyblokContext: StoryblokEntry = context.storyblok;

  return {
    ...storyblokContext,
    content: getParsedJSON(storyblokContext.content),
  };
};

//

const StoryblokEntryTemplate: React.FC<PageProps<Object, StoryblokEntryWithVenues>> = ({ pageContext }) => {
  return (
    <VenuesContext.Provider value={pageContext.venues}>
      <FiltersContext.Provider
        value={{
          clubs: pageContext.clubs,
          facilities: pageContext.facilities,
          buildings: pageContext.buildings,
          experienceTypes: pageContext.experienceTypes,
        }}
      >
        <LayoutContext.Provider value={pageContext.breadcrumbs}>
          <DynamicTemplate context={getContext(pageContext)} />
        </LayoutContext.Provider>
      </FiltersContext.Provider>
    </VenuesContext.Provider>
  );
};

export const Head: React.FC<HeadProps> = ({ pageContext, location }) => {
  const context = getContext(pageContext as StoryblokEntryWithVenues);
  const { SEO } = context.content;
  const URL =
    typeof window !== 'undefined'
      ? `${window.location.origin}${location.pathname}`
      : `${process.env.GATSBY_SITE_URL}${location.pathname}`;

  return <Meta seo={SEO} url={URL}></Meta>;
};

export default StoryblokEntryTemplate;
